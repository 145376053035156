/* 분전반현황 모달 창 스타일 */
.distribution-modal {
    position: fixed;  /* 모달 창을 뷰포트에 고정 */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    width: 80%;
    max-width: 800px;
    max-height: 90vh; /* 화면의 90%를 넘지 않도록 설정 */
    overflow-y: auto; /* 콘텐츠가 많을 경우 스크롤 가능 */
    box-sizing: border-box;
}

.distribution-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.distribution-close-button {
    position: absolute; /* 상대 위치 지정 */
    top: 20px; /* 모달창 내부에서 위쪽으로 20px */
    right: 20px; /* 모달창 내부에서 오른쪽으로 20px */
    background: #3498db;
    border: none;
    color: white;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
}

.distribution-close-button:hover {
    background: #2980b9;
}

.distribution-close-button:focus {
    outline: none;
}

.sp-highlight {
    border: 2px solid green;
}

.load-list {
    max-height: 60vh; /* 부하 리스트의 최대 높이 설정 */
    overflow: auto; /* 부하 리스트에 스크롤이 생기지 않도록 설정 */
    z-index: 1001; /* 모달창 안에서 부하 리스트가 표시되도록 설정 */
    position: relative;
}

/* 시설 모달 창 스타일 */
.facility-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    width: 80%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  .facility-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .facility-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #3498db;
    border: none;
    color: white;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
  }
  
  .facility-close-button:hover {
    background: #2980b9;
  }
  
  .facility-close-button:focus {
    outline: none;
  }
  
.pdf-fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    padding: 0;
    margin: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    outline: none;
    display: flex;
    flex-direction: column;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.pdf-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #3498db;
    border: none;
    color: white;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
}

.pdf-close-button:hover {
    background: #2980b9;
}

.pdf-close-button:focus {
    outline: none;
}

.modal-content {
    overflow-y: auto;
    max-height: calc(85vh);
}

/* 공통 Flex 스타일 */
.flex {
    display: flex;
    flex-wrap: wrap;
}

.w-full {
    width: 100%;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

/* 드롭다운 및 검색 필드 스타일 */
select,
input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
}

.flex-grow {
    flex-grow: 1;
}

.flex-grow-2 {
    flex-grow: 2;
    min-width: 40%; /* 검색어 입력 필드의 최소 폭 설정 */
}

/* 버튼 스타일 */
button.bg-blue-500 {
    background-color: #3498db;
    color: white;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

button.bg-blue-500:hover {
    background-color: #2980b9;
}

button:focus {
    outline: none;
}

@media only screen and (max-width: 600px) {
    .distribution-modal {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* 가로 및 세로 모두 중앙 정렬 */
        max-height: 90vh; /* 화면의 90% 높이까지만 사용 */
        overflow-y: auto; /* 내용이 많을 때 스크롤 허용 */
        margin-bottom: 0; /* 하단 여백 제거 */
    }

    .distribution-modal table {
        font-size: 0.8rem; /* 텍스트 크기를 조정하여 모바일에서 보기 좋게 설정 */
    }

    .distribution-modal td {
        padding: 0.5rem; /* 모바일 환경에서 패딩을 줄여 테이블의 크기를 줄임 */
    }

    .filter-button {
        font-size: 0.70rem; /* 버튼 글씨 크기를 줄임 */
        padding: 0.5rem 0.75rem; /* 버튼 크기를 줄여서 한 줄에 유지 */
    }

    .distribution-modal {
        width: 85%; /* 모달 창의 너비 */
        max-width: 800px; /* 최대 너비 */
        min-height: 50vh; /* 최소 높이를 50% 뷰포트 높이로 설정 */
        max-height: 90vh; /* 최대 높이를 90% 뷰포트 높이로 설정 */
        overflow-y: auto; /* 내용이 많을 경우 스크롤 가능 */
    }

    .distribution-modal .modal-content {
        font-size: 0.875rem; /* 텍스트 크기를 약간 줄임 */
    }
}
